export const APPLICATION_STATUSES = {
  APPLICATION_STARTED: 'Application Started',
  PRE_APPROVAL_ACCEPTED: 'Pre-qualified',
  PRE_APPROVAL_DENIED: 'Pre-qualification Denied',
  FULL_APPROVAL_STARTED: 'Full Approval Started',
  FULL_APPROVAL_SUBMITTED: 'Full Approval Submitted',
  FULL_APPROVAL_ACCEPTED: 'Application Approved',
  FULL_APPROVAL_DENIED: 'Full Approval Denied',
  FULL_APPROVAL_INFO_REQUIRED: 'Full Approval Info Required',
  OFFER_SUBMITTED: 'Offer Submitted',
  NO_LONGER_LOOKING: 'No Longer Looking',
  TOURING_HOUSES: 'Touring Houses',
  INVITED: 'Invited',
  PREPARING_OFFER: 'Preparing Offer',
  OFFER_ACCEPTED: 'Offer Accepted',
  OFFER_DECLINED: 'Offer Declined',
  FULFILL_CONDITIONS: 'Fulfill Conditions',
  FIRM_AGREEMENT: 'Firm Agreement',
  CLOSED_WON: 'Closed Won/Move In',
};
